import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from '../assets/logo.jpg'; // Adjust the path to your logo image
import { ReactComponent as EditIcon } from '../assets/Edit.svg';
import { ReactComponent as DroneIcon } from '../assets/Drone.svg';
import { ReactComponent as FilmIcon } from '../assets/Film.svg';
import { ReactComponent as ShutterIcon } from '../assets/Shutter.svg';
import { ReactComponent as WingsIcon } from '../assets/Wings.svg';

const Bars = () => {
  return (
    <div className="cinestoke-section">
      {/* First Layer */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: '2px solid white', // Top border for the first layer
          borderBottom: '2px solid white', // Bottom border for the first layer
          width: '100%',
        }}
      >
        {/* Left side with placeholder for Material-UI component */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRight: '2px solid white', // Vertical line between components
          padding: '20px',
          height: '80px', // Limit the height to match the horizontal lines
        }}>
          <ShutterIcon width="100" height="100" />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRight: '2px solid white', // Vertical line between components
          padding: '20px',
          height: '80px', // Limit the height to match the horizontal lines
        }}>
          <FilmIcon width="100" height="100" />
        </Box>

        {/* Cinestoke in the middle */}
        <Typography variant="h3" color="white" sx={{ textAlign: 'center', padding: '20px' }}>
          CINESTOKE
        </Typography>

        {/* Right side with placeholder for Material-UI component */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderLeft: '2px solid white', // Vertical line between components
          padding: '20px',
          height: '80px', // Limit the height to match the horizontal lines
        }}>
        <DroneIcon width="100" height="100" />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderLeft: '2px solid white', // Vertical line between components
          padding: '20px',
          height: '80px', // Limit the height to match the horizontal lines
        }}>
        <EditIcon width="100" height="100" />
        </Box>
      </Box>

      {/* Second Layer */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: '2px solid white', // Top border for the second layer
          borderBottom: '2px solid white', // Bottom border for the second layer
          width: '100%',
          height: '200px', // Limit the height of the second layer
        }}
      >
        {/* Left side with "Concept to Creation" */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRight: '2px solid white', // Vertical line between components
          padding: '20px',
          height: '80px', // Limit the height to match the horizontal lines
        }}>
          <Typography variant="h4" color="white">
            Concept to Creation
          </Typography>
        </Box>

        {/* Cinestoke logo in the middle */}
        <WingsIcon width="400" height="200" />

        {/* Right side with "Create Moments, Capture Feelings" */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderLeft: '2px solid white', // Vertical line between components
          padding: '20px',
          height: '80px', // Limit the height to match the horizontal lines
        }}>
          <Typography variant="h6" color="white">
            Create Moments, Capture Feelings
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Bars;
